import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className="project__footer section__padding">
      <div className="project__footer-heading">
        <h1 className="gradient__text">Top weekly articles sent to you for free.</h1>
      </div>
      <div className="project__footer-newsletter">
        <input required type="email" placeholder="E-mail" />
        <button type="button">Sign Up</button>
      </div>

      <div className="project__footer-smalltext">
        <p>By submitting your information you agree to the Terms & Conditions(opens in new tab) and <br/>Privacy Policy(opens in new tab) and are aged 16 or over.</p>
      </div>

      <div className="project__footer-links">
        <div className="project__footer-links_logo">
          <img src="" alt="logo" />
        </div>
        <div className="project__footer-links_div">
          <h4>Advertise with us</h4>
          <p>Overons</p>
          <p>Social Media</p>
          <p>Counters</p>
          <p>Contact</p>
        </div>
        <div className="project__footer-links_div">
          <h4>Company</h4>
          <p>Terms & Conditions</p>
          <p>Privacy Policy</p>
          <p>Contact</p>
        </div>
        <div className="project__footer-links_div">
          <h4>Get in touch</h4>
          <p>Crechterwoord K12 182 DK Alknjkcb</p>
          <p>085-132567</p>
          <p>info@payme.net</p>
        </div>
      </div>

      <div className="project__footer-copyright">
        <p>Copyright © 2023. All rights reserved.</p>
      </div>
    </div>
  )
};

export default Footer;
