import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ArticlePage, HomePage } from "../pages/";

const MainRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/:title" element={<ArticlePage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRoutes;