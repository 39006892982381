import React from "react";
import { Link } from 'react-router-dom';
import { ArticleThumbnail } from "..";
import { blog01, blog02, blog03, blog04, blog05, rhodes } from "./imports";
import "./articlestructure.css";

const Blog = () => {
  return (
  <>
    <div className="project__articlestructure section__padding" id="blog">
      <div className="project__articlestructure-heading">
        <h1 className="primary-text">Explore some of our hottest articles this week!</h1>
      </div>
      <div className="project__articlestructure-container">
        <div className="project__articlestructure-container_groupA">
        <Link to="/:title">
          <ArticleThumbnail imgUrl={rhodes} date="Sep 26, 2021" title="The Future of ChatGPT and the Upcoming GPT-4 Model" />
        </Link>

        </div>
        <div className="project__articlestructure-container_groupB">
          <ArticleThumbnail imgUrl={blog02} date="Sep 26, 2021" title="GPT-3 and Open  AI is the future. Let us exlore how it is?" />
          <ArticleThumbnail imgUrl={blog03} date="Sep 26, 2021" title="GPT-3 and Open  AI is the future. Let us exlore how it is?" />
          <ArticleThumbnail imgUrl={blog04} date="Sep 26, 2021" title="GPT-3 and Open  AI is the future. Let us exlore how it is?" />
          <ArticleThumbnail imgUrl={blog05} date="Sep 26, 2021" title="GPT-3 and Open  AI is the future. Let us exlore how it is?" />
        </div>
      </div>
    </div>
  </>
  )
};

export default Blog;
