import React from "react";
import { MainRoutes } from "./routing";
import GoogleAnalytics from "./GoogleAnalytics"

const App = () => {
  return (
    
      <div>
        <GoogleAnalytics />
        <MainRoutes />
      </div>
    
  );
};

export default App;
