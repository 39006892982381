import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

const GoogleAnalytics = () => {
  useEffect(() => {
    ReactGA.initialize('G-6F6270GTZT');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      
    </div>
  );
};

export default GoogleAnalytics;
