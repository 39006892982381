import React from "react";
import "./homepage.css";
import { Navbar, ArticleStructure, Footer } from "../../components";

const HomePage = () => {
  return (
    
      <div>
        <Navbar />
        <ArticleStructure />
        <Footer />
      </div>
    
  );
};

export default HomePage;
