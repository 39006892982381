import React, { useState, useEffect } from 'react'
import "./article.css"
import rhodes from "../../assets/rhodes.jpg";
import { db } from "../../firebase"
import { set, ref, onValue } from "firebase/database"

const Article = () => {
  const [title, setTitle] = useState([]);
  const [description, setDescription] = useState([])
  const [article, setArticle] = useState([])

  useEffect(() => {
    onValue(ref(db), (title) => {
      setTitle([]);
      const data = title.val();
      if (data !== null) {
        Object.values(data).map((title) => {
          setTitle((oldArray) => [...oldArray, title]);
        })
      }
    })
  }, [])

  useEffect(() => {
    onValue(ref(db), (description) => {
      setDescription([]);
      const data = description.val();
      if (data !== null) {
        Object.values(data).map((description) => {
          setDescription((oldArray) => [...oldArray, description]);
        })
      }
    })
  }, [])

  useEffect(() => {
    onValue(ref(db), (article) => {
      setArticle([]);
      const data = article.val();
      if (data !== null) {
        Object.values(data).map((article) => {
          setArticle((oldArray) => [...oldArray, article]);
        })
      }
    })
  }, [])

  return (
    <div className='project__article'>
      <div className='project__article-heading content__padding'>
      {title.map(title => (
        <>
          <h1>{title.title}</h1>
        </>
      ))}
        <p>Sep 26, 2021</p>
      </div>
      <div className='project__article-content content__padding'>
        {title.map(description => (
          <meta name="description" content={description.description}></meta>
        ))}
        <div className='project__article-image'>
        </div>
        {article.map(article => (
          <div dangerouslySetInnerHTML={{ __html: article.article }} />
      ))}
      </div>
    </div>
  )
}

export default Article
