import React from 'react'
import { Article, Footer, Navbar } from "../../components"
import "./articlepage.css"

const ArticlePage = () => {
  return (
    <div>
      <Navbar />
      <Article />
      <Footer />
    </div>
  )
}

export default ArticlePage
